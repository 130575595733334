import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as MapMarkerSvg } from "@images/icons/map-marker.svg"
import { ReactComponent as ClinicMapPin } from "@images/icons/clinic-map-pin.svg"
import { ReactComponent as HospitalMapPin } from "@images/icons/hospital-map-pin.svg"
import { ReactComponent as CenterMapPin } from "@images/icons/center-map-pin.svg"
import { ReactComponent as UrgentMapPin } from "@images/icons/urgent-map-pin.svg"

import classnames from "classnames"

import * as c from "@utilities/constants"

import "./style.scss"

const MapMarker = props => {
  const renderIcon = () => {
    switch (props.typeSlug) {
      case c.TYPE_URGENT_SLUG:
        return <UrgentMapPin lat={props.lat} lng={props.lng} />
      case c.TYPE_CLINIC_SLUG:
        return <ClinicMapPin lat={props.lat} lng={props.lng} />
      case c.TYPE_HOSPITAL_SLUG:
        return <HospitalMapPin lat={props.lat} lng={props.lng} />
      case c.TYPE_CENTER_SLUG:
        return <CenterMapPin lat={props.lat} lng={props.lng} />
      default:
        return (
          <MapMarkerSvg intent="secondary" lat={props.lat} lng={props.lng} />
        )
    }
  }

  return (
    <div
      className={classnames(
        "map-marker",
        props.className,
        `map-marker--${props.intent}`
      )}
    >
      {renderIcon()}
    </div>
  )
}

MapMarker.propTypes = {
  className: PropTypes.string,
  intent: PropTypes.oneOf(["primary", "secondary"]),
  lat: PropTypes.number,
  lng: PropTypes.number,
  typeSlug: PropTypes.string,
}
MapMarker.defaultProps = {}

export default MapMarker
