import React from "react"
import PropTypes from "prop-types"
import GoogleMapReact from "google-map-react"
import classnames from "classnames"
import MapMarker from "@components/common/MapMarker"

import "./style.scss"

class LocationMap extends React.Component {
  constructor(props) {
    super(props)

    this.client = null
    this.map = null
    this.handleGoogleMapsLoaded = this.handleGoogleMapsLoaded.bind(this)
  }

  handleGoogleMapsLoaded({ map, maps }) {
    this.client = maps
    this.map = map
    if (this.props.handleGoogleMapsLoaded) {
      this.props.handleGoogleMapsLoaded({ map, maps })
    }
  }

  render() {
    return (
      <div className={classnames("location-map", this.props.className)}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAPS_API_KEY }}
          libraries={["geometry", "geocode", "places"]}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onGoogleApiLoaded={this.handleGoogleMapsLoaded}
          yesIWantToUseGoogleMapApiInternals={true}
        >
          {this.props.searchResultCoords && (
            <MapMarker
              intent="secondary"
              lat={this.props.searchResultCoords.lat}
              lng={this.props.searchResultCoords.lng}
            />
          )}
          {this.props.locations &&
            this.props.locations.map((location, index) => {
              return (
                <MapMarker
                  key={`LocationMap-MapMarker--${index}`}
                  intent="primary"
                  typeSlug={(location.acf.type || {}).slug}
                  lat={(location.acf.map || {}).latitude}
                  lng={(location.acf.map || {}).longitude}
                />
              )
            })}
        </GoogleMapReact>
      </div>
    )
  }
}

LocationMap.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  searchResultCoords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  className: PropTypes.string,
  handleGoogleMapsLoaded: PropTypes.func,
  locations: PropTypes.array,
  zoom: PropTypes.number,
}
LocationMap.defaultProps = {
  center: {
    lat: 18.200178,
    lng: -66.664513,
  },
  searchResultCoords: null,
  locations: [],
  zoom: 9,
}

export default LocationMap
